import { httpMetaDatas, getAllDepts, getAllCampus, postAllCampus } from '@/services/common';

import { getLocalUserInfo } from '@/utils/filter';

export const FILE_NAMES = { label: 'name', value: 'id' };

/**
 *获取字典
 * @param {*} name
 * @param {*} region 默认值 customer
 * @returns [{}]
 */
export const httpDicts = async (name, region = 'customer') => {
  let options = {};
  try {
    const res = await httpMetaDatas({ region, name });
    if (res?.success) {
      const opt = res.data.optionValueV2?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
          text: el.name,
          key: el.id,
          children: el.children?.map((ite) => {
            return {
              ...ite,
              label: ite.name,
              value: ite.id,
              text: ite.name,
              key: ite.id,
            };
          }),
        };
      });
      options = {
        ...res.data,
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取组织架构树 全量 / 部分
export const httpAllCampus = async (params = {}) => {
  const { appId, limit } = params;
  if (!appId) return [];
  let result = [];
  let param;
  try {
    if (limit === 'part') {
      param = [getLocalUserInfo.appMemberId];
      result = await postAllCampus(appId, param);
    } else {
      result = await getAllCampus(appId);
    }
    return result || [];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

// 获取全部集团组织架构
export const httpAllDepts = async (params = {}) => {
  const { appId } = params;
  if (!appId) return [];
  try {
    const result = await getAllDepts({ appId });
    return result?.data || [];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};
