import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// 设置viewport
const viewport = document.querySelector('meta[name="viewport"]');
const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);

if (isMobile) {
  viewport.setAttribute('content', 'width=1600, initial-scale=1');
} else {
  viewport.setAttribute('content', 'width=device-width, initial-scale=1');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
