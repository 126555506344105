import request from '../utils/request';
import { getAppId } from '@/utils/index';
import { WX_BASEURL } from '@/consts/env';

/**
 * 上传oss的路径
 * @returns
 */
export const uploadFileToOss = `${request.SCRM_BASEURL}/api/v1.0/thunder/upload/file`;

function downloadURL(url, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadFile = (url, fileName = '', requestConfig = {}) => {
  return request
    .get(url, requestConfig)
    .then((resp) => {
      if (resp.status !== 200) {
        throw new Error('Download failed.');
      } else if (resp.blob) {
        return resp.blob();
      } else {
        return new Blob([resp]);
      }
    })
    .then((blob) => URL.createObjectURL(blob))
    .then((aurl) => {
      downloadURL(url, fileName);
      URL.revokeObjectURL(aurl);
    });
};

export const uploadFile = (formData) => {
  return request.post(`${uploadFileToOss}?server=xd-scrm-web&path=formPoster`, {
    data: formData,
    // headers:
  });
};

/** 请求oss里的json文件 */
export const getJsonFile = (url) => {
  return request.get(url);
};

const unique3 = (arr) => {
  const newArray = arr.filter((item, index) => {
    return arr.findIndex((f) => f.id === item.id) === index;
  });
  return newArray;
};

/**
 * 获取枚举数据
 * @param {*} params
 * @returns
 */
export const httpMetaDatas = (params) => {
  let appId = getAppId();
  if (params?.name === 'appId') {
    appId = 1;
  }
  return request.get(`/api/v1.0/app/${appId}/meta-data`, {
    params,
  });
};
/**
 * 根据appId查询所有分公司
 * @returns
 */
export const httpAppcorp = () => {
  return request.get(`/api/v1.0/app/{app-id}/app-corp`);
};
/**
 * 根据分公司查询校区
 * @param {id} 分公司id
 * @returns
 */
export const httpSchools = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/${param.id}/app-campus`);
};
/**
 * 根据校区查询校区下所有部门的人员
 * @param {id} 校区id
 * @returns
 */
export const httpAppmembers = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/dept/${param.id}/app-members`);
};

/*
 * 根据appCustomerId查询学员列表
 * @param {appCustomerId} 客户ID
 * @returns
 */
export const httpAppCustomerToStudengts = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/app-student/customer/${param.appCustomerId}`);
};

/**
 * 查询 ppts 各种常量
 * @param {appCustomerId} 客户ID
 * @param {category} 值 常量
 * @returns
 */
export const httPptsConstants = (param) => {
  return request.get(
    `/api/v1.0/app/{app-id}/app-member/${param.appCustomerId}/ppts-constants/category/${param.category}`,
  );
};

/**
 * 根据关键字查询单条appCustomer
 * @param {string} keyword 手机号、线索id、客户id、ps编号
 * @returns {result}
 */
export const onCheckCustomer = (payload) => {
  return request.get(`/api/v1.0/app/${getAppId()}/app-customer/keyword/${payload.keyword}`);
};

/**
 * 根据手机号查询客户是否已存在(学成含ppts数据)，用于通话弹屏
 * @param {string} phone 手机号
 * @returns {result}
 */
export const checkCustomerByPhone = (phone) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/fat-customer/${phone}`);
};

// 通过nsNo 查询潜客信息
export const httpPotentialCustomer = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/potential-customer/ns-no/${payload.nsNo}`);
};

/** 根据oa查询员工信息
 * @param {oa} oa
 * @returns
 */
export const getStaffInfoByOa = (oa) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/oa-name/job-data`, {
    params: {
      'oa-name': oa,
    },
  });
};

/** 根据oa查询员工信息
 * @param {scene} query 参数
 * @returns page 小程序路径
 */
export const httpMiniQrcode = (params) => {
  const url = `${WX_BASEURL}/api/v1.0/app/{app-id}/wx/ma/user/qrcode`;
  return request.get(url, {
    params,
    responseType: 'arraybuffer',
  });
};

// 获取明细数据-外呼统计
export const httpBehaviorStatic = (region, params) => {
  return request.get(`/api/v1.0/app/{app-id}/region/${region}-count-follow-states`, {
    params,
  });
};

/** 根据appId查询不同业务线下的组织架构
 * @param {payload} 【appId】
 */
export const getAllDepts = (payload) => {
  return request.get(`/api/v1.0/app/${payload.appId}/multi-departments`);
};

/*
 * 查询全量分公司、校区、人员
 */
export const getAllCampus = async (appId) => {
  return new Promise((resolve, reject) => {
    request.get(`/api/v1.0/app/${appId}/app-corp-campus`).then((res) => {
      const source = (res.data || []).map((m) => {
        return {
          ...m,
          label: m.name,
          value: m.deptId,
          children: (m.children || []).map((campus) => {
            return {
              ...campus,
              label: campus.name,
              value: campus.deptId,
              children: [],
              corpId: m.deptId,
              corpName: m.name,
            };
          }),
        };
      });
      resolve(source);
    });
  });
};
/*
 * 查询当前人所在分公司、校区
 */
export const postAllCampus = async (appId, data) => {
  return new Promise((resolve, reject) => {
    request
      .post(`/api/v1.0/app/${appId}/app-corp-campus`, {
        data,
      })
      .then((res) => {
        const sourceData = [];
        data.forEach((item) => {
          const itemData = res?.data?.[item] || [];
          sourceData.push(...itemData);
        });
        const source = (sourceData || []).map((m) => {
          return {
            ...m,
            label: m.name,
            value: m.deptId,
            children: (m.children || []).map((campus) => {
              return {
                ...campus,
                label: campus.name,
                value: campus.deptId,
                children: [],
                corpId: m.deptId,
                corpName: m.name,
              };
            }),
          };
        });
        resolve(source);
      });
  });
};

// 获取商品分类
export const httpGoodsTypes = async () => {
  return request.get(`/api/v1.0/app/{app-id}/goods-types`);
};

/**
 * ocr通用识别
 * @param {type} payload.type  票据类型
 * @param {url} payload.url  图片链接
 */
export const httpOcr = async (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/ocr`, {
    data: payload,
  });
};

/**
 * 根据appMemberId获取员工详情
 * @param appId
 * @param appMemberId
 */
export const getMemberInfo = (appId, appMemberId) => {
  return request.get(`/api/v1.0/app/${appId}/app-members/${appMemberId}`);
};
