import request from '../../utils/request';

/**
 * 获取公司主体
 * @param {Object} payload
 * @param {Number} payload.appId 事业线id
 * @param {Number} payload.createType 1: 未创建  2: 已创建
 */
export const httpCompany = (payload) => {
  return request.post(`/api/v1.0/app/sign/company`, {
    data: payload,
  });
};

/**
 * 获取经办人
 * @param {Object} payload
 * @param {Number} payload.appId 事业线id
 * @param {Number} payload.signId 电子签id
 */
export const httpOperator = (payload) => {
  return request.post(`/api/v1.0/app/operator/select`, {
    data: payload,
  });
};

/**
 * 获取订单类型
 * @param {Object} payload
 * @param {Number} payload.appId
 */
export const httpOrder = (payload) => {
  return request.post(`/api/v1.0/app/${payload?.appId}/goods-categorys`, {
    data: {
      pageIndex: 1,
      pageSize: 9999
    },
  });
};

/**
 * 获取合同模板
 * @param {Object} payload
 * @param {Number} payload.signId 电子签id
 * @param {Number} payload.enableFlag 状态 0: 停用  1: 启用
 */
export const httpTemplate = (payload) => {
  return request.post(`/api/v1.0/app/template/select`, {
    data: {
      ...payload,
    },
  });
};
