import {httpDicts} from '@/utils/tools/dict';
import {httpCompany} from "@/services/visaManage/common";

// 很多锁
const locks = {};

const region = {};

const visaModal = {
  state: {
    company: [], // 公司主体
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async getDicts(payload = {}, rootState) {
      const {type} = payload;
      const dicts = rootState?.visa?.[type];
      if (locks[type] || dicts?.length > 0) return;
      locks[type] = true;
      if (type === 'company') { // 公司主体
        const res = await httpCompany({
          pageSize: 9999,
          pageIndex: 1,
        });
        const result = (res?.data || []).map(item => {
          return {
            ...item,
            label: item.companyName,
            value: item.id,
          }
        });
        this.updateState({[type]: result});
        locks[type] = false;
      } else {
        const res = await httpDicts(type, region[type] || 'visa');
        this.updateState({[type]: res.pickerOptions});
        locks[type] = false;
      }
    },
  },
};

export default visaModal;
