export const MENUS = [
  {
    name: '系统首页',
    abbr: '首页',
    path: '/app',
    icon: 'home',
  },
  {
    name: '电子签管理',
    abbr: '电子签',
    path: '/visa',
    icon: 'caiwuzhongixn',
  },
];
