import { getAdmin, getStorage } from './filter';
import { FEIGN_DELAY } from '@/consts';
import { ORDER_PAY_DOMAIN } from '@/consts/env';
import { message } from 'antd';
import dayjs from 'dayjs';

export const jsonToTree = (data, propertyName, parentPropertyName) => {
  // 初始化结果数组，并判断输入数据的格式
  const result = [];
  if (!Array.isArray(data)) {
    return result;
  }
  // 使用map，将当前对象的id与当前对象对应存储起来
  const map = {};
  data.forEach((item) => {
    map[item[propertyName]] = item;
  });
  data.forEach((item) => {
    const parent = map[item[parentPropertyName]];
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
      // parent.children((parent.children = [])).push(item);
    } else {
      result.push(item);
    }
  });
  return result;
};

// 获取按钮权限
const getAuthorPermission = () => {
  return JSON.parse(sessionStorage.getItem('permissionBtn') || '[]');
};

// 获取拥有的页面权限列表
const getAuthorPageList = () => {
  return JSON.parse(localStorage.getItem('permissions_resources') || '[]');
};

/**
 * 获取权限是否存在
 * @param {String} auth 权限
 * @returns {Boolean} 结果
 */
export const getAuthorBtn = (auth) => {
  return true;
  // if (getAdmin()) return true;
  // const userAuthorList = getAuthorPermission();
  // if (!userAuthorList || !userAuthorList.length) {
  //   return false;
  // }
  // let bool = false;
  // userAuthorList.forEach((item) => {
  //   if (item.code === auth) {
  //     bool = true;
  //   }
  // });
  // return bool;
};

/**
 * 获取页面权限是否存在
 * @param {String} url 权限
 * @returns {Boolean} 结果
 */
export const getAuthorPath = (url) => {
  if (getAdmin()) return true;
  const pageList = getAuthorPageList();
  if (!pageList || !pageList.length) {
    return false;
  }
  let bool = false;
  pageList.forEach((item) => {
    if (item.url === url) {
      bool = true;
    }
  });
  return bool;
};

// 获取当前登录主体
export const getAppId = () => {
  return getStorage('xd_appid') || 1;
};

// 判断当前业务线是否学成或参照学成
export const isXuecheng = () => {
  return ['1', '6'].includes(getAppId());
};

/**
 * 手机号加密
 */
export const encryptionText = (text, type = 'phone') => {
  if (!text) {
    return '-';
  }
  if (type === 'phone') {
    return `${text.substr(0, 3)}****${text.substr(7)}`;
    // eslint-disable-next-line
  } else if (type === 'wechat') {
    if (text.length < 4) {
      return '****';
    }
    const middleIndex = Math.floor((text.length - 4) / 2);
    return `${text.substr(0, middleIndex)}****${text.substr(middleIndex + 4)}`;
  }
  return '-';
};

// 秒转换成时分秒
export const convertSecondsToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}小时`;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}分钟`;
  }
  if (remainingSeconds >= 0) {
    formattedTime += `${remainingSeconds}秒`;
  }

  return formattedTime;
};

// 延迟执行
export const delayExecute = (func, loadingContent) => {
  message.loading({
    key: 'feign',
    content: loadingContent || '操作中...',
  });
  setTimeout(() => {
    func();
    message.destroy('feign');
  }, FEIGN_DELAY);
};

// 复制文案
export const copyText = (text) => {
  const input = document.createElement('input');
  input.value = text || '';
  document.body.appendChild(input);
  input.select();
  document.execCommand('Copy');
  input.style.display = 'none';
  message.success('复制成功');
};

// 只能输入正整数
export const limitDecimals = (value) => value.replace(/^(0+)|[^\d]+/g, '');

// 千分位格式化
export const limitFormat = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const limitDecimalsLeng = (value) => {
  const reg = /^(\\-)*(\d+)\.(\d\d).*$/;
  if (typeof value === 'string') {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
  } else if (typeof value === 'number') {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
  } else {
    return '';
  }
};

// 生成H5支付链接
export const createPayUrl = (orderNo) => {
  return `${ORDER_PAY_DOMAIN}pay?orderNo=${orderNo}`;
};

/**
 * 检验是否为合法的手机号、坐席、400电话
 * @param {*} phone
 */
// 手机号码正则表达式
const mobilePattern = /^1[3456789]\d{9}$/;
// 座机号码正则表达式
const landlinePattern = /^0\d{2,3}\d{7,8}$/;
// 400电话正则表达式
// const siPattern = /^400d{7}$/;
export const validateMobilePattern = (phone) => {
  if (!phone) {
    message.warning('请输入电话号码');
    return false;
  }
  const isValidateMobile = mobilePattern.test(phone);
  const isValidateLandline = landlinePattern.test(phone);
  if (isValidateMobile || isValidateLandline) {
    return true;
  } else {
    message.warning('请输入正确的号码');
    return false;
  }
};

// antd 计算序号
export const getColumnKey = (page, index) => {
  const { pageIndex = 1, pageSize = 10 } = page || {};
  return (pageIndex - 1) * pageSize + (index + 1);
};

// 手机号码格式化纯数字
export const formateCurPhone = (e) => {
  const val = e.target.value.trim();
  const targetPhone = val.replace(/[^\d]/g, '');
  return targetPhone;
};
// ArrayBuffer转换为base64
export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytesStr = new Uint8Array(buffer);
  for (let len = bytesStr.byteLength, i = 0; i < len; i++) {
    binary += String.fromCharCode(bytesStr[i]);
  }
  const res = `data:image/png;base64,${window.btoa(binary)}`;
  return res;
}

// canvas图片-画圆
export function drawCircleImage(qrCodeEl) {
  const canvas = document.createElement('canvas');
  canvas.width = qrCodeEl.width;
  canvas.height = qrCodeEl.height;
  const context = canvas.getContext('2d');
  const radius = qrCodeEl.width;
  const halfWidth = radius / 2;
  context.save();
  const size = 2 * halfWidth;
  context.arc(halfWidth, halfWidth, halfWidth, 0, 2 * Math.PI);
  context.clip();
  context.drawImage(qrCodeEl, 0, 0, size, size);
  context.restore();
  return canvas;
}

/**
 * 获取url参数
 * @param {String} name 参数名
 * @param {String} url url
 * @returns {String} 参数值
 */
export function getQuery(name, url = window.location.href) {
  const NURL = new URL(url);
  const querySerach = new URLSearchParams(NURL.search);
  return querySerach.get(name);
}

/** 跳转到页面指定class的位置
 * @param {String} className class名称
 */
export function scrollToElement(className) {
  const element = document.querySelector(`.${className}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

/** 手机号客户端校验 */
export const checkMobile = (_, value) => {
  const reg = /^1[3456789]\d{9}$/;
  if (!reg.test(value)) {
    return Promise.reject(new Error('请输入正确的手机号'));
  } else return Promise.resolve();
};

/** 处理时间区间参数为字符串 */
export const timeRangeToString = (time) => {
  if (time) {
    const dateStart = dayjs(time[0].$d).format('YYYY-MM-DD HH:mm:ss');
    const dateEnd = dayjs(time[1].$d).format('YYYY-MM-DD HH:mm:ss');
    return `${dateStart}~${dateEnd}`;
  }
  return undefined;
};

/** 查询fieldValues中所有处理为字符串的时间区间 */
export const handleTimeRangeToString = (fieldValues) => {
  const result = {};
  Object.keys(fieldValues).forEach((key) => {
    if (
      Array.isArray(fieldValues[key]) &&
      fieldValues[key].length === 2 &&
      fieldValues[key][0]?.$d
    ) {
      result[key] = timeRangeToString(fieldValues[key]);
    }
  });
  return result;
};

/** 处理时长~时长为字符串，自动补齐边界 */
export const handleDurationRangeToString = (duration) => {
  if (!duration) return undefined;

  if (typeof duration.start === 'number' || typeof duration.end === 'number') {
    let { start, end } = duration;
    if (typeof start === 'number' && typeof end !== 'number') {
      end = 99999;
    }
    if (typeof end === 'number' && typeof start !== 'number') {
      start = 0;
    }
    return `${start}~${end}`;
  }
};
