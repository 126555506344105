import { getLocalUserInfo } from '@/utils/filter';
import { MENUS } from '@/consts/menus';

function flatMenus(list) {
  const res = [];
  list.forEach((item) => {
    if (item.childMenu?.length) {
      res.push(...flatMenus(item.childMenu));
    } else {
      res.push(item);
    }
  });
  // console.log(res, 'res');
  return res;
}

const userInfo = getLocalUserInfo();

const Permission = {
  state: {
    permission: MENUS || [], // 权限列表
    userInfo,
    refreshPermissions: false,
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    getPermission() {
      const menuList = MENUS;
      this.updateState({
        permission: flatMenus(menuList),
        refreshPermissions: true,
      });
    },
    setUserInfo(payload) {
      this.updateState({
        userInfo: payload,
      });
    },
  },
};

export default Permission;
