import { getStorage, setStorage } from '../../utils/filter';
import { httpAllDepts, httpDicts, httpAllCampus } from '@/utils/tools/dict';

const campuslocks = {};
const region = {
  // appId: 'common',
};

const appStore = {
  state: {
    collapsed: getStorage('sider_collapsed') ? JSON.parse(getStorage('sider_collapsed')) : true,
    appId: [], // 业务线
    AllDepts: {}, // 组织架构缓存
    departments: [], // 当前组织架构
    allCampusDeptsCache: {}, // 校区组织架构缓存
    campusDepts: [], // 当前校区组织架构
    partCampusDeptsCache: {}, // 校区组织架构缓存
    partCampusDepts: [], // 当前校区组织架构
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    updateCollapsed(_, rootState) {
      const { collapsed: collapsedCache } = rootState.app;
      this.updateState({
        collapsed: !collapsedCache,
      });
      setStorage('sider_collapsed', JSON.stringify(!collapsedCache));
    },
    async getDicts(payload = {}, rootState) {
      const { type, ...rest } = payload;
      const dicts = rootState?.app?.[type];
      // 课程类目 不在状态管理缓存
      if (campuslocks[type] || dicts?.length > 0) return;
      campuslocks[type] = true;
      const res = await httpDicts(type, region[type] || 'common');
      this.updateState({ [type]: res.pickerOptions });
      campuslocks[type] = false;
    },
    async getAllCampusDepts(payload, rootState) {
      const { appId, limit = 'part' } = payload;
      if (!appId) return;
      const lockKey = `${appId}_${limit}`;
      let dicts = rootState?.app.allCampusDeptsCache?.[appId];
      if (limit === 'part') {
        dicts = rootState?.app.partCampusDeptsCache?.[appId];
      }
      if (campuslocks[lockKey]) return;
      if (dicts?.length > 0) {
        if (limit === 'part') {
          this.updateState({
            partCampusDepts: dicts,
          });
        } else {
          this.updateState({
            campusDepts: dicts,
          });
        }

        return;
      }
      campuslocks[lockKey] = true;
      const res = await httpAllCampus(payload);
      if (limit === 'part') {
        this.updateState({
          partCampusDepts: res,
          partCampusDeptsCache: { ...rootState.app.partCampusDeptsCache, [appId]: res },
        });
      } else {
        this.updateState({
          campusDepts: res,
          allCampusDeptsCache: { ...rootState.app.allCampusDeptsCache, [appId]: res },
        });
      }
      campuslocks[lockKey] = false;
    },
    async getAllDepts(payload, rootState) {
      const { appId } = payload;
      if (!appId) return;
      const lockKey = `${appId}`;
      const dicts = rootState?.app.AllDepts?.[appId];
      if (campuslocks[lockKey]) return;
      if (dicts?.length > 0) {
        this.updateState({
          departments: dicts,
        });
        return;
      }
      campuslocks[lockKey] = true;
      const res = await httpAllDepts(payload);
      // console.log(res, 'rrrrrr');
      this.updateState({
        departments: res,
        AllDepts: { ...rootState.app.AllDepts, [appId]: res },
      });
      campuslocks[lockKey] = false;
    },
  },
};

export default appStore;
