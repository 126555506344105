import React, { Component } from 'react';
import { ConfigProvider, App, message } from 'antd';
import zhCn from 'antd/es/locale/zh_CN';
import locale from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import './App.css';

class Apps extends Component {
  componentDidMount() {
    message.config({
      top: 45,
      maxCount: 3,
    });
  }

  render() {
    return (
      <ConfigProvider
        locale={locale}
        prefixCls={systemName}
        theme={{
          components: {
            Menu: {
              zIndexPopup: 1999,
            },
          },
        }}
      >
        <Provider store={store}>
          <App>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </App>
        </Provider>
      </ConfigProvider>
    );
  }
}
export default Apps;
