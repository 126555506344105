export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeStorage = (key) => {
  return localStorage.removeItem(key);
};

export const setSession = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const getSession = (key) => {
  return sessionStorage.getItem(key);
};

export const removeSession = (key) => {
  return sessionStorage.removeItem(key);
};

// 获取用户信息
export const getLocalUserInfo = () => {
  const userInfo = getStorage('userInfo');
  return userInfo ? JSON.parse(userInfo) : {};
};

export const getAdmin = () => {
  // '000250208', ydd
  const userInfo = getLocalUserInfo();
  return [
    '000250208', // ydd
  ].includes(userInfo?.userId);
};
